import { useEffect, useState } from 'react';
import './DealsPage.scss';
import Search from '../components/Search'
import DealCard from '../components/DealCard';
import api from '../apis/cheapshark';

const DealsPage = () => {

    const [deals, setDeals]  = useState([]);
    const [onSale, setOnSale] = useState(false);

    const getDeals = (name, sale) => {
        sale = (sale === '' || !sale) ? false : true;
        let url = `/deals?pageSize=12&onSale=${sale}`;
        if (name) {
            url += `&title=${name}`;
        }
        api.get(url).then(res => {
            setDeals(res.data);
        })
    };

    const handleSearch = (e) => {
        if (e.code === 'Enter') {
            getDeals(e.target.value);

        }
    };

    const onSaleFilter = (e) => {
        setOnSale(!onSale);
        getDeals(null, onSale);
    };

    useEffect(() => {
        getDeals();
    }, []);

    return (
        <div className='container deals-page'>
            <header>
                <h1>Deals <Search placeholder='Search deals by name' handleSearch={handleSearch} /></h1>
            </header>
            <div className='filters'>
                <h3>Filters <i className='fas fa-chevron-down'></i></h3>
                <label htmlFor='on-sale'>
                    On Sale <input id='on-sale' name='on-sale' type='checkbox' onChange={onSaleFilter} checked={onSale} />
                </label>
            </div>
            <section className='deals-listing'>
                {deals.length > 0 && deals.map(deal => <DealCard key={deal.dealID} deal={deal} />)}
            </section>
        </div>
    )
}

export default DealsPage;
