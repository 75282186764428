import './Footer.scss';

const Footer = () => {
    return (
        <footer className='container'>
            <p>Developed by Tlangelani Mboweni</p>
        </footer>
    )
}

export default Footer;
