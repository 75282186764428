import { useEffect, useState } from 'react';
import './StoresPage.scss';
import Search from '../components/Search';
import StoreCard from '../components/StoreCard';
import api from '../apis/cheapshark';
import Message from '../components/Message';

const StoresPage = () => {

    const [stores, setStores] = useState([]);
    const [searchName, setSearchName] = useState('');

    useEffect(() => {
        getStores();
        filterStoresByName('sila');
    }, []);

    const getStores = async (name) => {
        let res = await api.get('/stores');
        setStores(res.data);

        /**
         * Following block is comment out because my IP was blocked with the following error: Rate Limit
         */
        // res.data.forEach(async (store, idx) => {
        //     let deals = await getStoreDeals(store.storeID);
        //     store.totalDeals = deals.length;
        //     storeData.push(store);

        //     if (idx === res.data.length - 1) {
        //         setStores(storeData);
        //     }
        // });
    };

    const getStoreDeals = async (storeID) => {
        let res = await api.get(`/deals?storeID=${storeID}`);
        return res.data;
    };

    const filterStoresByName = (name) => {
        console.log({name});
        if (name === '') {
            getStores();
        }

        let filteredStores = stores.filter(store => store.storeName.toLowerCase().includes(name.toLowerCase()));
        setStores(filteredStores);
    }

    const handleSearch = (e) => {
        setSearchName(e.target.value);
        if (e.code === 'Enter') {
            filterStoresByName(searchName);
        }
    }

    const showMessage = (type, message) => {
        
    }

    return (
        <div className='container stores-page'>
            <header>
                <h1>Stores <Search placeholder='Search stores by name' handleSearch={handleSearch} /></h1>
            </header>
            <section className='stores-listing'>
                {stores.length > 0 && stores.map(store => <StoreCard key={store.storeID} store={store} />)}
            </section>
            <Message type='error' message='No data found!' show={showMessage} />
        </div>
    )
}

export default StoresPage;
