import './Search.scss';

const Search = ({placeholder, handleSearch}) => {
    return (
        <div className='search-input'>
            <input 
                type='text' 
                placeholder={placeholder} 
                onKeyUp={(e) => handleSearch(e)}
            />
            <i className='fas fa-search'></i>
        </div>
    )
}

export default Search;
