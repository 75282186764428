import { Link } from 'react-router-dom';
import EllipsisText from 'react-ellipsis-text';
import './DealCard.scss';
import Price from './Price';

const DealCard = ({deal}) => {
    const {title, normalPrice, salePrice, dealID}  = deal;
    return (
        <div className='deal-card'>
            <h4><EllipsisText text={title} length={28} /></h4>
            <Price normalPrice={normalPrice} salePrice={salePrice} showYouSave={false} />
            <Link className='view-more' to={`/deal/${dealID}`}>View More</Link>
        </div>
    )
}

export default DealCard
