import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DealDetailPage from '../pages/DealDetailPage';
import DealsPage from '../pages/DealsPage';
import StoresPage from '../pages/StoresPage';
import Footer from './Footer';
import Nav from './Nav';

const App = () => {
    return (
        <Router>
            <Nav />
            <main>
                <Switch>
                    <Route exact path='/' component={DealsPage} />
                    <Route exact path='/deal/:id' component={DealDetailPage} />
                    <Route exact path='/stores' component={StoresPage} />
                </Switch>
            </main>
            <Footer />
        </Router>
    )
}

export default App;
