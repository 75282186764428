import { Link } from 'react-router-dom';
import EllipsisText from 'react-ellipsis-text';
import './StoreCard.scss';

const StoreCard = ({store}) => {
    const { storeID, storeName, images, totalDeals = 60 }  = store;
    return (
        <div className='store-card'>
            <div className="image">
                <img src={`https://www.cheapshark.com/${images.banner}`} alt={storeName}/>
            </div>
            <h4><EllipsisText text={storeName} length={28} /></h4>
            <p className='deals'>{totalDeals} deals</p>
            <Link className='view-more' to={`/store/${storeID}`}>View More</Link>
        </div>
    )
}

export default StoreCard
