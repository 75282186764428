import { useState } from 'react';
import './Message.scss';

const Message = ({type, message}) => {

    const [show, setShow] = useState(true);

    const onClose = () => {
        setShow(false);
    };

    return (show && 
        <div className='message'>
            {type === 'success' ? <i class="fas type success fa-info-circle"></i> : <i class="fas type error fa-exclamation-triangle"></i>}
            <i onClick={onClose} className="fas close fa-times-circle"></i>
            <p>{message}</p>
        </div>
    )
}

export default Message
