import React, { useEffect, useState } from 'react';
import './Price.scss';

const Price = ({normalPrice, salePrice, showYouSave}) => {
    normalPrice = Number(normalPrice);
    salePrice = Number(salePrice);
    const [savePrice, setSavePrice] = useState(0);
    const [onSale, setOnSale] = useState(0);

    useEffect(() => {
        setSavePrice(normalPrice - salePrice);
        setOnSale(salePrice > 0 && salePrice < normalPrice);
    }, [normalPrice, salePrice]);

    return (
        <div className={onSale ? 'price on-sale' : 'price'} data-checka={onSale}>
            <span className='normal'>R{normalPrice}</span>
            <span className='sale'>R{salePrice}</span>
            {showYouSave === true && <span className='save'>You save R{savePrice}</span>}
        </div>
    )
}

export default Price
