import { useEffect, useState } from 'react'
import './DealDetailPage.scss';
import { useParams } from 'react-router-dom';
import api from '../apis/cheapshark';
import Price from '../components/Price';
import OtherGameCard from '../components/OtherGameCard';

const DealDetailPage = () => {

    let { id } = useParams();

    const [deal, setDeal] = useState(null);
    const [stores, setStores] = useState([]);
    const [relatedDeals, setRelatedDeals] = useState([]);
    const [store, setStore] = useState('');
    const [message, setMessage] = useState('Loading...');

    const getStores = () => {
        api.get(`/stores`).then(res => {
            setStores(res.data);
            getRelatedDeals();
        });
    };

    const getDeal = () => {
        api.get(`/deals?id=${id}`).then(res => {
            if (res.data.length === 0) {
                setMessage('No deal found!');
            }
            setDeal(res.data);

            // set store
            setStore(getStoreName(res.data.gameInfo.storeID));
        });
    };

    const getStoreName = (storeID) => {
        let storeInfo = stores.filter(store => parseInt(store.storeID, 10) === parseInt(storeID, 10));
        return storeInfo && storeInfo.length > 0 ? storeInfo[0].storeName : 'N/A';
    };

    const getRelatedDeals = () => {
        api.get(`/deals?title=Deponia The Complete Journey&pageSize=6`).then(res => {
            setRelatedDeals(res.data);
        });
    }

    useEffect(() => {
        getStores();
        getDeal();
    }, []);

    if (deal !== null) {
        const { name, retailPrice, salePrice, thumb } = deal.gameInfo;
        return (
            <div className='container deal-detail-page'>
                <section className='details'>
                    <h1>{name}</h1>
                    <Price normalPrice={retailPrice} salePrice={salePrice} showYouSave={true} />
                    <div className='available'>
                        <h2>Available at <span>{store}</span></h2>
                    </div>
                    <div className='image'>
                        <img src={thumb} alt={name} />
                    </div>
                </section>
                <section className='other-deals'>
                    <h4>Other deals for this games</h4>
                    <div className="other-games">
                        {relatedDeals.length > 0 && relatedDeals.map(deal => {
                            deal.storeName = getStoreName(deal.storeID);
                            return <OtherGameCard key={deal.dealID} deal={deal} />
                        })}
                    </div>
                </section>
            </div>
        )
    } else {
        return <p className='container deal-detail-page'>{message}</p>
    }
}

export default DealDetailPage;
