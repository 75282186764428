import { Link } from 'react-router-dom';
import './OtherGameCard.scss';
import Price from './Price'

const OtherGameCard = ({deal}) => {
    console.log(deal);
    const { title, dealID, normalPrice, salesPrice, isOnSale, storeName } = deal;
    return (
        <div className='other-game-card'>
            <h5>{title}</h5>
            <h6>Available at <span>{storeName}</span></h6>
            <Price normalPrice={normalPrice} salePrice={salesPrice} isOnSale={isOnSale} showYouSave={false} />
            <Link className='view-more' to={`/deal/${dealID}`}>View More</Link>
        </div>
    )
}

export default OtherGameCard
