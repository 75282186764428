import { Link, useLocation } from 'react-router-dom';
import './Nav.scss';

const Nav = () => {

    const pathname = useLocation().pathname;

    return (
        <nav>
            <div className='container'>
                <Link to='/' className='brand'>
                    <img src='/img/logo.svg' alt='CheapShark' />
                    <h2>CheapShark</h2>
                </Link>
                <ul>
                    <li><Link className={pathname === '/' ? 'active' : ''} to='/'>Deals</Link></li>
                    <li><Link className={pathname === '/games' ? 'active' : ''} to='/games'>Games</Link></li>
                    <li><Link className={pathname === '/stores' ? 'active' : ''} to='/stores'>Stores</Link></li>
                </ul>
            </div>
        </nav>
    )
}

export default Nav
